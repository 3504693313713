.landing {
  height: 100vh;
}

.landing-content {
  background-image: url("https://res.cloudinary.com/ddwwsfeeh/image/upload/v1626420686/landing-background_bwpv1i.png");
  background-size: cover;
  background-position: right-bottom;
  height: 100vh;
  background-repeat: no-repeat;
}
.landing-content .linkbtn {
  font-size: 12px;
  height: 45px;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  margin-bottom: 10px;
  color: #fff;
  border-radius: 2px;
  cursor: pointer;
  border: none;
  background-color: #109fda;
  border-radius: 40px;
  border-width: 0px;
  width: 18%;
  height: 35px;
}

.landing-content .landing-heading {
  padding-left: 2%;
  margin-top: 3.1%;
  text-align: justify;
  width: 45%;
  font-size: 40px;
  margin-bottom: 2.5%;
  font-weight: 700;
}
.landing-content .span-txt {
  color: #109fda;
}
.landing-content .landing-para {
  padding-left: 2%;
  text-align: justify;
  text-justify: inter-word;
  width: 25%;
  margin-bottom: 2.5%;
  font-size: 16px;
  color: #000;
  font-weight: 500;
}
.input {
  padding-left: 2%;
  display: flex;
  position: right;
  margin-bottom: 2.5%;
}
.input .text-field {
  border-radius: 50px;
  outline: none;
  border: 1px solid #c7d2e0;
  background-color: #e4fbff;
  height: 40px;
  width: 250px;
  font-size: 12px;
  font-weight: 400;
  padding-left: 2%;
}

.input .link-btn {
  font-size: 13px;
  height: 40px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  padding: 6px 16px;
  margin-bottom: 10px;
  color: #fff;
  border-radius: 50px;
  cursor: pointer;
  border: none;
  margin-left: 10px;
  background-color: #16aace !important;
}

.landing-content .dwnld-btn {
  padding-left: 30px;
  display: flex;
  position: right;
  cursor: pointer;
}

.landing-content .dwnld1 {
  margin-right: 3%;
}

@media (min-width: 320px) and (max-width: 620px) {
  .landing {
    padding-right: 20px;
    height: 500px;
  }

  .landing-content .landing-heading {
    font-size: 30px;
    width: 100%;
    padding-left: 7%;
    margin-bottom: 10%;
  }

  .landing-content .landing-para {
    width: 100%;
    padding-left: 7%;
    margin-bottom: 10%;
  }

  .input {
    padding-left: 7%;
    margin-bottom: 8%;
  }

  .input .text-field {
    padding-left: 5%;
  }
  .landing-content .link-btn {
    font-size: 12px;
    width: 45%;
    margin-bottom: 10%;
  }
  .landing .landing-content {
    background-image: none;
  }
}

.howItWorks-heading {
  margin: 5% 0;
}

.howItWorks .howItWorks-content {
  display: flex;
  flex-direction: row;
}
.howItWorks-content .howItWorks-img {
  width: 100px;
}
.howItWorks-heading .span-txt {
  color: #109fda;
}

.howItWorks-content p {
  font-size: 15px;
  font-weight: 700;
  margin-top: 8%;
  width: 45%;
  text-align: justify;
}
.howItWorks-step1-para,
.howItWorks-step3-para {
  padding-left: 40px;
}
.howItWorks-step2-para,
.howItWorks-step4-para {
  padding-right: 50px;
}
@media (min-width: 320px) and (max-width: 620px) {
  .howItWorks {
    margin-bottom: 10%;
  }
  .howItWorks .howItWorks-heading {
    padding: 7% !important;
  }
  .howItWorks-content {
    padding-left: 30px !important;
  }
  .step1 p,
  .step2 p,
  .step3 p,
  .step4 p {
    margin-top: 20%;
    font-weight: 500;
    width: 70%;
  }
}


.featureInNumber {
  background-color: #109fda;
  margin-top: 5%;
}

.featureInNumber .featureInNumber-content {
  margin: 1.6% 0;
}

.featureInNumber-content .featureInNumber-img {
  width: 50px;
}

.featureInNumber-num {
  font-size: 20px;
  font-weight: 350;
  color: #fff;
  margin-top: 0.6%;
}

.featureInNumber-text {
  font-size: 18px;
  font-weight: 350;
  color: #fff;
  margin-top: 0.6%;
}
.featurelist-heading {
  margin: 5% 0;
}
.featurelist-heading .span-txt {
  color: #109fda;
}

.serviciableCities {
  padding: 1.25% 0;
  background-color: #c8e6f5;
}

.serviciableCities .serviciableCities-body {
  padding: 2% 0;
  background-color: #fff;
  border-radius: 30px;
}

.serviciableCities-img {
  margin-top: 5%;
  width: 65px;
  height: 65px;
  border-radius: 65px;
  cursor: pointer;
}

@media (min-width: 320px) and (max-width: 620px) {
  .serviciableCities .serviciableCities-body {
    background-color: #c8e6f5;
  }
}
.talkToUs {
  margin-bottom: 5%;
}
.talkToUs .heading {
  margin: 5% 0;
  font-size: 30px;
}
.talkToUs-form .form-body {
  margin-bottom: 3%;
}

.talkToUs-form .form-body .form-content {
  background: #fff 0 0;
  border: 1px solid #555;
  border-radius: 30px;
  height: 6vh;
  width: 70%;
  margin-bottom: 1%;
  padding-left: 3%;
  outline: none;
}

.message-field {
  height: 15vh !important;
  padding: 2% 0 0 5%;
}

.talkToUs-form .submit-btn {
  border: 2px solid #16aace !important;
  border-radius: 30px;
  height: 5vh;
  font-size: 18px;
  padding: 0;
  background-color: #16aace;
  color: #fff !important;
  width: 90px;
  font-weight: 400;
}

.talkToUs-para {
  color: #16aace;
  text-align: justify;
  margin: 3% 0 7% 10%;
  font-size: 17px;
  font-weight: 500;
}

@media (min-width: 320px) and (max-width: 620px) {
  .talkToUs-form {
    margin-bottom: 10%;
  }
  .talkToUs-para {
    padding-left: 20%;
    font-size: 15px;
  }
}

.footer-h6 {
  margin-bottom: 4% !important;
}
.footer a {
  color: #fff !important;
  text-decoration: none !important;
}
.footer {
  background-color: #262626;
  padding: 4% 0 0 0;
}

.footer .footer-body {
  color: #fff;
  text-align: justify;
}

.footer-content {
  font-weight: 400;
  font-size: 13px;
}

.section1,
.section2 {
  border-right: 1px solid #718096;
}
.section2,
.section3 {
  text-align: center;
}
.brand-img {
  width: 100px;
  height: auto;
  margin-bottom: 5%;
  margin-left: -10px;
}

.facebook-icon,
.twitter-icon,
.instagram-icon {
  margin-left: 20px;
}

.phone-icon,
.envelope-icon,
.map-icon {
  margin-right: 5px;
}

.follow-us,
.contact-us {
  margin-bottom: 10%;
}

.info p {
  padding-top: 3%;
}

.playstore-icon {
  margin-top: 10%;
  width: 150px;
  cursor: pointer;
}

.appstore-icon {
  margin: 10% 0;
  width: 150px;
  cursor: pointer;
}

.bottom-footer {
  background-color: #171717;
  padding: 10px 0;
  text-align: center;
  margin: 2% 0 0;
}

.bottom-footer h5 {
  color: #fff;
  font-size: 12px;
}

@media (min-width: 320px) and (max-width: 620px) {
  .footer-content {
    padding: 5% 0;
    border-right: none;
    width: 95% !important;
    text-align: center;
  }
  .section1,
  .section2 {
    border-bottom: 1px solid #718096;
  }

  .download-links {
    border-bottom: none !important;
  }
}
