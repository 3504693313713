.navbar-nav .nav-item {
  padding: 0 20px;
}
.nav-brand .brand-img {
  width: 150px;
  height: auto;
}
.navbar-nav .nav-item .nav-link {
  color: #109fda !important;
  font-weight: 600;
}

@media (min-width: 320px) and (max-width: 620px) {
  .nav-brand {
    margin: 0px !important;
  }
}

.login-container{
  color: #6d7174;
}

.login-phone-container{
  padding-top: 45px;
  padding-bottom: 25px;
}

.phone-input{
  width: 100%;
}

.login-container p{
  font-size: 13px;
  position: absolute;
  bottom: 10px;
}

.close-icon{
  position: absolute;
  top: 10px;
  left: 20px;
  color: #6d7174;
  cursor: pointer;
}