.listitem-link{
    text-decoration: none;
}

.css-10hburv-MuiTypography-root{
    font-size: 15px !important;
}

.listitem-link:hover{
    text-decoration: none;
}

/* layout app bar */
.css-zxtyt4-MuiPaper-root-MuiAppBar-root{
    background-color: white !important;
    box-shadow: 1px 0px 20px 0px rgb(255 255 255), 0px 4px 5px 0px rgb(151 143 143 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%) !important;
}

.drawer-logo{
    cursor: pointer;
}

.dashboard-container{
    padding: 80px 60px 50px;
    width: 80%;
    text-align: initial;
}

.dashboard-container .property-number-text{
    font-size: 13px;
}